/* You can add global styles to this file, and also import other style files */
@import "rfs/scss";
@import "app/app.global.scss";

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
.mat-card {
  font-family: "Open Sans", sans-serif;
  padding: 0.4rem !important;
}

html,
body {
  height: 100%;
  font-size: 1vw;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  display: flex;
}

.hide {
  display: none !important;
  opacity: 0.1;
}

.fill {
  flex: 1 1 100%;
  height: 100%;
  flex-grow: 10000;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

.debug {
  border: 1px dashed red;
}

.debug>* {
  border: 1px dotted blue;
}

.debug2 {
  border: 1px dashed violet;
}

.debug2>* {
  border: 1px dotted greenyellow;
}

* {
  box-sizing: border-box;
}

.widthFill {
  width: 100%;
}

.heightFill {
  height: 100%;
}

.primary-text {
  color: white;
}

.primary-dark-text {
  color: $primary-dark;
}

.mat-header-row {
  background-color: $dark-grey;
  color: white;
}

.mat-header-cell {
  color: white;
}

.secondary-text {
  color: rgba(84, 32, 14, 1);
}

.tertirary-text {
  color: rgb(118, 118, 118);
}

.centerText {
  text-align: center;
}

.centerY {
  margin-top: auto;
  margin-bottom: auto;
}

a {
  color: $secondary-bright;
  font-weight: bold;
}

a.linkAlt {
  color: white;
  cursor: pointer;
}

a.linkAlt:visited {
  color: white;
  font-weight: bold;
}

a:visited {
  color: $secondary-bright;
  font-weight: bold;
}

.small {
  font-size: 0.85rem;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-icon-button,
.mat-icon-button .mat-icon {
  font-size: 1.1rem;
  line-height: 1.75rem !important;
  font-weight: bold;
}

.mat-flat-button {
  padding: 0 1.1rem !important;
}

.mat-icon-button,
.mat-icon-button .mat-icon {
  height: unset !important;
  width: unset !important;
}

.mat-raised-button.mat-blue,
.mat-flat-button.mat-accent {
  color: #fff;
}

.validText {
  color: #36f440;
}

.align-right {
  margin-left: auto !important;
  margin-right: 0;
}

.align-top {
  margin-top: 0;
  margin-bottom: auto;
}

.align-left {
  margin-left: 0;
  margin-right: auto !important;
}

.align-bottom {
  margin-top: auto;
  margin-bottom: 0;
}

.centerX {
  margin-left: auto;
  margin-right: auto;
}

.no-pad {
  padding: 0;
}

p {
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
}

.themePrimaryTextInverse {
  color: #54200e;
}

.navLink,
.navLink:visited {
  color: rgba(118, 118, 118, 1);
  text-decoration: none;
}

tr.mat-row {
  height: 2.75rem !important;
}

tr.mat-header-row {
  height: 2.75rem !important;
}

.mat-paginator {
  display: block;
  height: 3rem;
}

.mat-paginator-outer-container {
  height: 2.75rem;
}

.mat-paginator-container {
  min-height: 2.7rem !important;
  height: 100%;
  align-content: center;
}

.um {
  height: 1.1rem;
}

.flatButton {
  padding: 0 0.5rem;
  margin: 0;
  font-size: 0.75rem;
  line-height: 0.75rem;
  background-color: transparent;
  border: 0;
  height: 100%;
  display: block !important;

  p {
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin: auto;
  }
}

.fillHeight {
  height: 100%;
}

.flexy {
  display: flex;
}

.buttonToggleThemeingWorkaround button {
  color: #fff !important;
  background: $primary-dark;
  height: 100%;

  .mat-button-toggle-label-content {
    line-height: 1.5rem !important;
  }
}

.buttonToggleThemeingWorkaround button[aria-pressed="true"] {
  background: $primary-dark-focus;
}

.buttonToggleThemeingWorkaroundSmall button {
  color: #fff !important;
  background: $primary-dark;
  font-size: 1.1rem !important;
  height: 100%;

  .mat-button-toggle-label-content {
    line-height: 1.75rem !important;
    padding: 0 1.25rem !important;
  }
}

.buttonToggleThemeingWorkaroundSmall button[aria-pressed="true"] {
  background: $primary-dark-focus;
}

.mat-expansion-panel-header {
  height: auto !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.appExpCategory .mat-expansion-panel .mat-expansion-panel-header,
.appLegendCat .mat-expansion-panel .mat-expansion-panel-header {
  height: auto !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

/*Typography*/
.appFieldLabel {
  font-weight: bold;
  color: $neutral;
  font-size: 0.9rem;
  line-height: 0.9rem;
}

.appFieldContent,
.mat-form-field,
.mat-select-value-text {
  color: $neutral;
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.mat-option-text {
  font-size: 0.9rem;
}

.appGenCardLink {
  font-size: 0.9rem;
}

.appCatContainer,
.layerTitle,
.appLegendLabel {
  font-size: 0.9rem;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  color: $neutral;
  font-size: 0.9rem;
}

.appBrownActionButton {
  color: $secondary-dark;
  font-size: 1.1rem;
  font-weight: bold;
}

.appH1Title {
  color: $primary-dark;
  font-size: 1.4em;
  font-weight: bold;
}

.appH2Header,
.mat-card-header .mat-card-title.appH2Header {
  color: $primary-dark;
  font-size: 1.3em;
  font-weight: bold;
}

.appH3SecondaryHeader,
.mat-card-header .mat-card-title.appH3SecondaryHeader {
  color: $primary-dark;
  font-size: 1.3em;
  font-weight: bold;
}

.appBasemapToggle button .mat-button-toggle-label-content {
  font-size: 0.60rem;
}

/*Ol Overrides*/
.ol-scale-line {
  left: auto !important;
  right: 8px;
  bottom: 3rem;
  background-color: $primary-dark;
}

.mat-expansion-indicator::after {
  padding: 0.25rem !important;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding: 0.5rem;
}

.mat-cell,
.mat-select-value,
// .mat-option-text,
.mat-paginator-range-label,
.mat-paginator-page-size-label,
.mat-button-toggle-label-content,
.mat-dialog-content,
.appClickable,
.appEditIconContainer {
  font-size: 0.9rem;
}

.mat-sort-header-content {
  font-size: 1.1rem;
}

router-outlet+* {
  width: 100%;
  height: 100%;
}

.mat-select-trigger {
  width: 100%;
}

.mat-paginator-page-size {
  margin-right: auto !important;
}

.mat-dialog-actions .mat-button-base+.mat-button-base,
.mat-dialog-actions .mat-mdc-button-base+.mat-mdc-button-base {
  margin-left: 0 !important;
}

.mat-expansion-panel-body {
  padding: 0 0.3rem 0.3rem !important;
}

.mat-tooltip {
  font-size: 1.1rem !important;
}

.optionhax {
  max-height: 20rem !important;
}

.buttonhax {
  padding: 0.5rem;
  margin: 0;
  min-width: unset;
  min-height: unset;
  line-height: 1.1rem;
}

.growhax {
  max-width: 60rem;
  overflow: hidden;
}

.statBlock {
  font-size: 0.95rem;

  p:first-of-type {
    font-size: 1.15em;
    line-height: 1.15em;
    font-weight: bold;
    margin: 0;
  }

  p:last-of-type {
    font-size: 0.75em;
    line-height: 0.85em;
    font-weight: bold;
    margin: 0;
  }
}

.outline-font {
  //-webkit-text-stroke: 0.05em black;
  -webkit-text-fill-color: white;
}

.appSolidCard {
  border-style: solid;
  border-width: 1px;
  border-color: $primary-dark;
}

.appExpCategory .mat-expansion-panel,
.layerListItem .mat-expansion-panel,
.appLegendCat .mat-expansion-panel {
  border-style: solid;
  border-width: 1px;
  border-color: $primary-dark;
  box-shadow: 0 0;
  margin-top: 0.5rem;
}

.layerListItem .mat-expansion-panel {
  margin-bottom: 0.5rem;
}

.appCatContainer .mat-accordion {
  padding-bottom: 0.25rem;
}

.appLegendAccordContainer .mat-accordion {
  padding-bottom: 0.25rem;
  margin-top: 0.5rem;
}

.appCustomTooltip {
  cursor: pointer;
}

.appSecondaryIcon {
  color: $secondary-dark;
  height: 20px;
  width: 20px;
  margin-left: .25rem;
}

.mat-button-base.appSquareButton {
  border-radius: 12px !important;
}

.mat-card-header-text.appCardHeaderUnderline {
  margin: 0;
  color: $primary-dark;
}

.mat-card-header-text {
  margin: 0 !important;
}

.mat-card-header.appCardHeaderUnderline {
  border-bottom: 1px solid $primary-dark;
  margin-bottom: 0.5rem;
}

.appProjectHeaderContainer {
  border-bottom: 1px solid $dark-grey;
}

.appSkinnyFontButton {
  font-weight: 400;
}

.appClickable {
  cursor: pointer;
}

.appTrashCan {
  color: $secondary-bright;
}

.appTrashCanHeaderBuffer {
  margin-right: 1.5rem;
}

.appExpExpandIcon {
  color: $secondary-bright;
}

.mat-body, .mat-body-1, .mat-typography {
  line-height: 23px;
}

.appConfirmButton,
.mat-flat-button.appConfirmButton {
  min-width: 12.5rem;
  min-height: 2.5rem;
  font-weight: 400;
}

.appCatName {
  font-weight: bold;
  margin-top: 0.25rem;
  font-size: 1.2rem;
}

// Scrollbar
::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #b5b1ae;
  outline: 1px solid #b5b1ae;
}

@media screen and (max-width: $medium-screen-width) {
  /*Typography*/
  .appFieldLabel {
    font-size: 1.1rem;
    line-height: 1.1rem;
  }

  .appFieldContent,
  .mat-form-field,
  .mat-select-value-text {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }

  .mat-option-text {
    font-size: 1.1rem;
  }

  .appGenCardLink {
    font-size: 1.1rem;
  }

  .appCatContainer,
  .layerTitle,
  .appLegendLabel {
    font-size: 1.1rem;
  }

  .mat-list-base .mat-list-item .mat-list-item-content,
  .mat-list-base .mat-list-option .mat-list-item-content {
    font-size: 1.1rem;
  }


  .mat-cell,
  .mat-select-value,
  .mat-paginator-range-label,
  .mat-paginator-page-size-label,
  .mat-button-toggle-label-content,
  .mat-dialog-content,
  .appClickable,
  .appEditIconContainer
   {
    font-size: 1.2rem;
  }
  
  .mat-sort-header-content {
    font-size: 1.3rem;
  }

  .mat-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-icon-button,
  .mat-icon-button .mat-icon {
    font-size: 1.2rem;
  }

  .appBrownActionButton {
    font-size: 1.2rem;
  }

  .appBasemapToggle button .mat-button-toggle-label-content {
    line-height: 30px;
  }
}

@media print {
  #appHeaderBar {
    display: none;
  }

  div {
    background-color: blue;
  }
}