// Core colors
$primary-dark: #2F483C;
$primary-dark-transparent-70: #2f483cce;
$primary-dark-hover: #21352b;
$primary-dark-focus: #16241d;
$primary-light: #82AA96;
$secondary-dark: #785117;
$secondary-light: #AF8950;
$secondary-bright: #955011;
$white: #FFFFFF;
$light-grey: #F2F4F3;
$med-grey: #979797;
$table-grey: #D3D3D3;
$dark-grey: #434343;
$highlight: #FFF698;
$main-bg: #F5F5F5;
$warn: #ad5656;
$neutral: #444;

// Fonts
$base-high-res-font-size: 0.9rem;
$base-low-res-font-size: 1.1rem;

// Screen sizes
$large-screen-width: 1920px;
$medium-largescreen-width: 1600px;
$medium-screen-width: 1366px;
