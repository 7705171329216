/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* https://materialtheme.arcsine.dev/?c=eyJwYWxldHRlIjp7InByaW1hcnkiOnsibWFpbiI6IiMyZjQ4M2MiLCJsaWdodGVyIjoiIzJmNDgzYyIsImRhcmtlciI6IiMxZDMwMjcifSwiYWNjZW50Ijp7Im1haW4iOiIjYWY4OTUwIiwibGlnaHRlciI6IiNlN2RjY2IiLCJkYXJrZXIiOiIjOTY2YzM3In0sIndhcm4iOnsibWFpbiI6IiNmZjEyMTIiLCJsaWdodGVyIjoiI2ZmYjhiOCIsImRhcmtlciI6IiNmZjBhMGEifSwibGlnaHRUZXh0IjoiIzJmNDgzYyIsImxpZ2h0QmFja2dyb3VuZCI6IiNmYWZhZmEiLCJkYXJrVGV4dCI6IiNmZmZmZmYiLCJkYXJrQmFja2dyb3VuZCI6IiMyYzJjMmMifSwiZm9udHMiOlt7InRhcmdldCI6ImRpc3BsYXktNCJ9LHsidGFyZ2V0IjoiZGlzcGxheS0zIn0seyJ0YXJnZXQiOiJkaXNwbGF5LTIifSx7InRhcmdldCI6ImRpc3BsYXktMSJ9LHsidGFyZ2V0IjoiaGVhZGxpbmUifSx7InRhcmdldCI6InRpdGxlIn0seyJ0YXJnZXQiOiJzdWJoZWFkaW5nLTIifSx7InRhcmdldCI6InN1YmhlYWRpbmctMSJ9LHsidGFyZ2V0IjoiYm9keS0yIn0seyJ0YXJnZXQiOiJib2R5LTEifSx7InRhcmdldCI6ImJ1dHRvbiJ9LHsidGFyZ2V0IjoiY2FwdGlvbiJ9LHsidGFyZ2V0IjoiaW5wdXQiLCJzaXplIjpudWxsfV0sImljb25zIjoiU2hhcnAiLCJsaWdodG5lc3MiOnRydWV9
*/
@use '~@angular/material' as mat;

@import '~@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons+Sharp';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$fontConfig: (display-4: mat.define-typography-level(112px, 112px, 300, 'Open Sans', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Open Sans', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Open Sans', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Open Sans', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Open Sans', 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, 'Open Sans', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Open Sans', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Open Sans', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Open Sans', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Open Sans', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Open Sans', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Open Sans', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Open Sans', 1.5px));

// Foreground Elements

// Light Theme Text
$dark-text: #2f483c;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

mat.$light-theme-foreground-palette: (base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

mat.$dark-theme-foreground-palette: (base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

mat.$light-theme-background-palette: (background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
mat.$dark-theme-background-palette: (background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat.core($fontConfig);

// Theme Config

body {
  --primary-color: #2f483c;
  --primary-lighter-color: #2f483c;
  --primary-darker-color: #1d3027;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$light-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (main: #2f483c,
  lighter: #2f483c,
  darker: #1d3027,
  200: #2f483c, // For slide toggle,
  contrast : (main: $light-primary-text,
    lighter: $light-primary-text,
    darker: $light-primary-text,
  ));
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #AF8950;
  --accent-lighter-color: #e7dccb;
  --accent-darker-color: #966c37;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (main: #AF8950,
  lighter: #e7dccb,
  darker: #966c37,
  200: #AF8950, // For slide toggle,
  contrast : (main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ));
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  // --warn-color: #ff1212;
  --warn-color: #AD5656;
  --warn-lighter-color: #ffb8b8;
  --warn-darker-color: #ff0a0a;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (main: #AD5656,
  lighter: #ffb8b8,
  darker: #ff0a0a,
  200: #AD5656, // For slide toggle,
  contrast : (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ));
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-flat-button.mat-accent {
  background-color: #966c37;
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons Sharp', 'Material Icons', sans-serif;

  .mat-badge-content {
    font-family: 'Open Sans', 'Roboto', sans-serif;
  }
}



.mat-button-base {
  border-radius: 20em !important;
}

.mat-checkbox>label>span {
  white-space: pre;
}
